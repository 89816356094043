import Swiper from "swiper"
import { Navigation, Pagination } from 'swiper/modules';

document.addEventListener('DOMContentLoaded', function() {
  // -----------------------------------------------------------------
  // push slider
  class PushSlider extends HTMLElement {
    constructor() {
      super()
    }
    connectedCallback() {
      // console.log('push-slider is mounted')
      
      // elements
      this.swiper = null
      this.slider = this.querySelector('.js-slider__swiper')
      this.prev = this.querySelector('.js-slider__prev')
      this.next = this.querySelector('.js-slider__next')
      this.slides = this.querySelectorAll('.swiper-slide')
      this.contents = this.querySelectorAll('.js-slider__content')

      // communicating with slider
      this.controllingDates = this.getAttribute('data-target')
      this.datesElement = document.querySelector(this.controllingDates)

      // launch stuff
      this.initSwiper()
    }        
    disconnectedCallback() {
      // console.log('push-slider is unmounted')
      if (this.swiper.destroy) this.swiper.destroy(true, true)
    }
    initSwiper() {
      this.swiper = new Swiper(this.slider, {
        modules: [Navigation, Pagination],
        speed: 300,
        slidesPerView: 1,
        centeredSlides: true,
        spaceBetween: 0,
        loop: true,
        navigation: {
          prevEl: this.prev ?? null,
          nextEl: this.next ?? null,
        },
        pagination: {
          el: '.swiper-pagination',
        },
        on: {
          slideChangeTransitionStart: (swiper) => {
            this.revealContent(swiper.realIndex)
            this.datesElement?.onSlideChange(swiper.realIndex)
          }
        }
      })
      // hide nav if not enough slides
      if (this.slides.length <= 1) {
        this.classList.add('is-no-slide')
      }
    }
    revealContent(index) {
      this.contents.forEach((content, i) => {
        content.classList.remove('is-active', 'is-initial')
        if (i !== index) {
          content.classList.add('is-hidden')
        } else {
          content.classList.remove('is-hidden')
        }
      })
      this.contents[index].classList.add('is-active')
    }
    // triggered by dates-nav.js
    navTo(index) {
      this.swiper.slideToLoop(index)
    }
  }

  customElements.define('push-slider', PushSlider)
})