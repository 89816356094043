// Scroll to top
document.addEventListener('DOMContentLoaded', function() {
  class BackToTop extends HTMLElement {
    constructor() {
      super();
    }

    connectedCallback() {
      // props
      this.link = this.querySelector('a')
      this.currentScrollTop = 0;
    
      // events
      // - click
      this.scrollTopHandler = this.scrollTop.bind(this);
      this.link.addEventListener('click', this.scrollTopHandler)
    }
    
    disconnectedCallback() {
      this.link.removeEventListener('click', this.scrollTopHandler)
    }

    scrollTop(event) {
      event.preventDefault()
      
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }

  }

  customElements.define('back-top', BackToTop)
})