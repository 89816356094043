document.addEventListener('DOMContentLoaded', function() {
  // -----------------------------------------------------------------
  // contact iframe
  class ContactModule extends HTMLElement {
    constructor() {
      super()
    }
    connectedCallback() {
      console.log('contact module')
      // elements
      this.iframeElement = this.querySelector('iframe')

      // binding handler
      this.bindedOnResize = this.resize.bind(this)

      // listeners
      // this.selectElement.addEventListener('click', this.bindedHandleChangeValue)
      window.addEventListener('message', this.bindedOnResize, false);
    }        
    disconnectedCallback() {
      window.removeEventListener('message', this.bindedOnResize, false);
    }
    resize(e) {
      if (!e) return 
      
      const currentHeight = this.iframeElement.getAttribute('height') - 100;
      if (currentHeight != e.data) {
        if (Number.isInteger(e.data)) {
          document.getElementById('contactConso').setAttribute('height', e.data + 100);
        }
      }
    }
  }

  customElements.define('contact-module', ContactModule)
})