// Logo animation on sroll
document.addEventListener('DOMContentLoaded', function() {
  window.onscroll = function () {
    let logo = document.getElementById('s-header__logo');
    if (window.scrollY > 100) {
      logo.classList.add('is-scrolled')
    } else {
      logo.classList.remove('is-scrolled')
      logo.classList.remove('is-scrolled')
    }
  }
})