import { gsap } from "gsap";
    
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

gsap.registerPlugin(ScrollTrigger);

document.addEventListener('DOMContentLoaded', function() {
  // -----------------------------------------------------------------
  // styled select with a floating label
  class ParallaxWrapper extends HTMLElement {
    constructor() {
      super()
    }
    connectedCallback() {
      // console.log('parallax-wrapper')
      // elements
      this.parallaxElements = this.querySelectorAll('.js-parallax__element')
      this.timeline = gsap.timeline()

      // binding handler
      this.bindedInit = this.init.bind(this)

      
      // on load
      this.init()

      // listeners
      // this.selectElement.addEventListener('click', this.bindedHandleChangeValue)
    }        
    disconnectedCallback() {
    }
    init() {
      this.parallaxElements.forEach((element, index) => {

        // class to dispatch left and right
        if (index % 2 === 0) element.classList.add('is-odd')
        
        // class to reaveal the stuff
        gsap.set(element, { opacity: 1 });

        // gsap shit
        const animation = gsap.to(element, {
          y: '-150%',
          scrollTrigger: {
            trigger: element,
            scrub: true,
            start: 'top bottom+=100',
            end: 'bottom top-=100',
          },
        });
        this.timeline.add(animation)

      });
    }
  }

  customElements.define('parallax-wrapper', ParallaxWrapper)
})