document.addEventListener('DOMContentLoaded', function() {
  // -----------------------------------------------------------------
  // search input with the clear button
  class SearchInput extends HTMLElement {
    constructor() {
      super()
    }
    connectedCallback() {
      // console.log('search-input loaded')
      // elements
      this.inputElement = this.querySelector('.js-searchinput__input')
      this.clearElement = this.querySelector('.js-searchinput__clear')

      // binding handler
      this.bindedRevealClear = this.revealClear.bind(this)
      this.bindedClearValue = this.clearValue.bind(this)

      // on load
      this.bindedRevealClear()

      // listeners
      this.clearElement.addEventListener('click', this.bindedClearValue)
    }        
    disconnectedCallback() {
      this.clearElement.removeEventListener('click', this.bindedClearValue)
    }
    // handlers
    clearValue() {
      this.inputElement.value = ''
      this.revealClear()
    }
    revealClear() {
      if (this.inputElement.value) {
        this.clearElement.classList.add('is-visible')
      } else {
        this.clearElement.classList.remove('is-visible')
      }
    }
  }

  customElements.define('search-input', SearchInput)
})